.photo-editor {
  text-align: center;

  .upload-select {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .icon-wrapper {
    position: relative;
    padding: 0px 30px;
  }
  
  .icon-wrapper .anticon {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 1;
  }
  
  .icon-wrapper .icon-wrapper-active {
    color: rgba(0, 0, 0, 0.45);
  }
  
  .icon-wrapper .anticon:first-child {
    left: 0;
  }
  
  .icon-wrapper .anticon:last-child {
    font-size: 20px;
    top: -5px;
    right: 0;
  }

  .ant-avatar {
    .ant-avatar-string {
      font-size: 100px;
    }
  }
}
@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;