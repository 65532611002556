@import '../../../../../styles/theme.less';

.ir21-a1 {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  height: 100%;
  padding: 0 24px;

  .ir21-a1-form {
    padding-bottom: 5px;
    min-width: 1050px;
    max-width: 1500px;

    &__title {
      font-size: 14px;
      font-weight: 600;
      margin-top: 12px;
      padding: 5px 18px;
      background-color: @primary-bg-color;
    }

    &__field {
      display: flex;
      padding: 6px;
      padding-left: 0;

      &-row {
        padding: 8px 20px 0;
      }
    }
  }

  .ant-input-number-input {
    text-align: right;
    padding-right: 25px;
  }

  .collapsed {
    .ant-collapse-header {
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: @secondary-bg-color;

      .ant-collapse-arrow {
        top: 10px;
        color: @secondary-color;
      }
    }
  }

  .auto-fill {
    font-weight: bold;
    border: none;
    text-align: right;
    padding-right: 26px;
  }

  // margin
  .mt5 {
    margin-top: 5px !important;
  }
  .mt10 {
    margin-top: 10px !important;
  }

  // text align
  .ta-r {
    text-align: right;
  }

  // font weight
  .font-bold {
    font-weight: bold;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;