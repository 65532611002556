@import '../../../../styles/theme.less';

.ir21-forms {
  background-color: @white-color;
  max-width: 1700px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 0 24px;
    display: flex;
    align-items: center;

    &-emselect {
      min-width: 250px;

      .ant-select-selector{
        min-height: 50px;
        vertical-align: middle;

        .ant-select-selection-item {
          padding-top: 5px;
        }
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
    }

    &-right {
      flex: 1;
      text-align: right;
    }

    .person {
      margin-right: 24px;
    }
  }

  &__body {
    flex: 1;
    overflow: hidden;

    &-left, &-right {
      flex: 1;
    }
    &-left {
      margin-right: 12px;

      &-header {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;


        > .ant-select {
          width: 260px;
        }
      }

      &-footer {
        margin-top: 12px;
      }
    }
    &-right {
      margin-left: 12px;
    }

    .content-bold {
      font-weight: bold;
    }

    .amount-positive {
      color: @success-color;
    }
    .amount-negative {
      color: @danger-color;
    }
  }

  .ant-tabs {
    display: flex;
    flex: 1;
    height: 100%;

    .ant-tabs-nav {
      padding: 0 16px;
    }

    .ant-tabs-content-holder {
      display: flex;
      flex: 1;
      overflow: auto;
    }
  }

  &__action-bar-buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .btn-icon {
      min-width: min-content;
    }
  }

  .ant-tabs-extra-content {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-slider {
      width: 100px;
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;