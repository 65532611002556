.em-select {
  &__person {
    display: flex;
    align-items: center;

    &__info {
      flex: 1;
      margin-left: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > h1 {
        font-size: 14px;
        line-height: 1.4;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: @darkish-color;
      }

      span {
        display: block;
        font-size: 12px;
        line-height: 1.1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &.ant-select-single.ant-select-show-arrow {
    min-width: 200px;

    &.em-select-large {
      .ant-select-selector {
        min-height: 50px;
        vertical-align: middle;

        .ant-select-selection-item {
          padding-top: 5px;
        }
      }
    }

    &.em-select-default {
      .ant-select-selection-item {
        display: flex;
        align-items: center;

        .em-select__person {
          .ant-avatar {
            width: 20px !important;
            height: 20px !important;
            line-height: 20px !important;
            font-size: 14px !important;

            .ant-image .ant-image-img {
              width: 20px !important;
              height: 20px !important;
            }

            .ant-avatar-string {
              line-height: 20px !important;
            }
          }

          &__info {
            margin-left: 7px;

            h1 {
              line-height: 1;
            }

            &-desc {
              display: none;
            }
          }
        }
      }
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      .ant-select-selection-item {
        border: 0;
        font-weight: bold;

        .em-select__person {
          .ant-avatar {
            width: 20px !important;
            height: 20px !important;
            line-height: 20px !important;
            font-size: 14px !important;

            .ant-image .ant-image-img {
              width: 20px !important;
              height: 20px !important;
            }

            .ant-avatar-string {
              line-height: 20px !important;
            }
          }

          &__info {
            margin-left: 7px;

            span {
              display: none;
            }
          }
        }
      }
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;