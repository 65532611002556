@import '../../../../../styles/theme.less';

.edit-leave-entitlement-drawer {
  .entitlement__subtitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-left: -24px;
    margin-right: -24px;
    padding: 7px 18px;
    background-color: @primary-bg-color;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-collapse > .ant-collapse-item {
    > .ant-collapse-header {
      margin-left: -24px;
      margin-right: -24px;
      padding: 0 0 0 24px;

      > .ant-collapse-arrow {
        top: 12px;
      }
    }

    > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 24px;
    }
  }

  hr {
    height: 1px;
    border: 0;
    border-top: 1px solid @border-color-base;
  }

  .value-readonly {
    display: inline-block;
    width: 90px;
    padding: 0 11px;
  }

  .minus-sign {
    color: @danger-color;
  }

  .label--small {
    font-size: 11px;
    color: @grey-color;
  }
}


@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;