@import '../../../../styles/theme.less';

.claim-entitlement {
  &__main {
    padding: 0 24px;
  }

  &__sider {
    > .ant-space {
      width: 100%;
    }

    .em-select {
      width: 100%;
    }
  }

  &__scrolly {
    height: calc(100vh - 180px);
    overflow-y: auto;
    padding: 0 15px;
  }

  &__title {
    max-width: 650px;
    margin-bottom: 12px;
    padding-left: 15px;
  }

  &__current {
    max-width: 650px;

    > .ant-card-body {
      padding-top: 0;
    }

    &-taken {
      padding: 0 20px;
    }

    &-amount {
      border: 1px solid @light-grey-color;
      border-radius: 10px;
      padding: 2px 16px;

      label {
        color: @text-color-secondary;
        font-size: 13px;
      }
      .amount-display {
        text-align: center;
        font-weight: 500;
        margin-bottom: 6px;
      }
    }
    &-operator {
      text-align: center;
    }
    &-balance {
      border: 0;
      background-color: @secondary-bg-color;
    }
  }

  &__taken-choices {
    margin: 36px 0 24px;

    .ant-checkbox-group {
      display: block;
    }

    &-item {
      width: 200px;
      text-align: center;
      border-radius: 50px;
      border: 1px solid @light-grey-color;
      background-color: @white-color;

      &-body {
        padding: 0;
      }

      &.selected {
        background-color: @primary-bg-color;
        border-color: @primary-color;
      }

      label {
        display: block;
        color: @text-color-secondary;
        font-size: 13px;
        line-height: 1;
        padding: 7px;

        .status-label {
          color: @primary-color;
          margin-bottom: 5px;
        }
      }

      .ant-checkbox {
        display: none;

        + span {
          padding-left: 0;
        }
      }

      .amount-display {
        color: @darkish-color;
        margin-bottom: -10px;
        font-weight: 600;
      }
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;