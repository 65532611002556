@import '../../../../../styles/theme.less';

.ssreal-time-attendance-list {
  .attendance-row-item {
    &__person {
      display: flex;
      align-items: center;

      &__info {
        flex: 1;
        margin-left: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        > h1 {
          font-size: 14px;
          line-height: 1.4;
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: @darkish-color;
        }

        .ant-tag {
          margin-top: 3px;
        }
      }

      &__clocked {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &__avatar {
        position: relative;
      }

      &__onshift {
        border-radius: 10px;
        width: 10px;
        height: 10px;
        background-color: @success-color;
        position: absolute;
        bottom: -2px;
        right: -2px;
      }

      &__offshift {
        border-radius: 10px;
        width: 10px;
        height: 10px;
        background-color: @warning-color;
        position: absolute;
        bottom: -2px;
        right: -2px;
      }

      &__absent {
        border-radius: 10px;
        width: 10px;
        height: 10px;
        border: 1px solid @grey-color;
        background-color: @white-color;
        position: absolute;
        bottom: -2px;
        right: -2px;
      }
    }

    &--no-expand {
      margin-right: 24px;
    }
  }

  &__details {
    margin-left: 30px;
  }

  &__details-time {
    width: 80px;
  }

  &__details-separator {
    width: 15px;
  }

  .ant-list-item {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-list-empty-text {
    display: none;
  }

  .ant-collapse {
    &.ant-collapse-icon-position-end {
      > .ant-collapse-item {
        .ant-collapse-header {
          padding: 0 24px 0 0;

          .ant-collapse-arrow {
            right: 0;
            color: @light-grey-color;
          }
        }
      }
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;