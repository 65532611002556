.shift-day {
  .ant-descriptions-row > td {
    padding-bottom: 0;
  }

  .ant-descriptions-item {
    &-label {
      display: flex;
      align-items: center;
      color: @grey-color;
      font-size: @form-item-label-font-size;
  
      .ant-typography {
        color: @grey-color;
      }
    }

    &-content {
      font-weight: 500;
      padding-right: 10px;
      justify-content: flex-end;
      text-align: right;
    }
  }

  &__list-item {
    padding-top: 0;
  }

  .sub-header {
    margin-bottom: 8px;

    .link {
      font-weight: normal;
    }
  }

  &__normal {
    .ant-descriptions-item-label {
      width: 100px;
    }
  }

  &__clocking {
    .ant-descriptions-item-label {
      width: 180px;
    }
  }

  &__grace {
    .ant-descriptions-item-label {
      width: 130px;
    }
  }

  &__ot {
    .ant-descriptions-item-label {
      width: 120px;
    }
  }
  
  &__half-day {
    .ant-descriptions-item-label {
      width: 80px;
    }
  }

  &__section-title {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
    
    &--space {
      margin-top: 16px;
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;