@import '../../../../styles/theme.less';

.tenant-card {
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  .ant-card-body {
    padding: 0;
  }

  &--selected {
    border: 2px solid @primary-color;
  }

  .role-icon {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px 0 0 12px;

    &.fa-user-gear {
      background-color: @primary-bg-color;
      color: @primary-color;
    }

    &.fa-user-group {
      background-color: #fafafa;
      color: @primary-color;
    }
  }

  .selected-icon {
    position: absolute;
    top: 6px;
    right: 10px;
    color: @primary-color;
  }

  .tenant-logo {
    font-size: 24px;
    font-weight: 600;
    margin: 24px;

    .ant-image {
      background-size: contain;
      background-repeat: no-repeat;
    }

    .ant-avatar-string {
      line-height: 45px !important;
    }
  }

  .card-info-col {
    display: flex;
    align-items: center;
  }

  .card-info {
    &-title {
      font-size: 16px;
      font-weight: 500;

      display: inline;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-wrap: break-word;

      .ant-tag {
        margin-left: 5px;
      }
    }
    &-description {
      color: @grey-color;
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;