@import '../../styles/theme.less';

@card-head-font-size: 40px;

.ant-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  &-head {
    border-bottom: 0;

    &-title {
      font-weight: 500;
      padding: @card-head-padding 0 0;
    }
  }

  &-extra {
    padding: @card-head-padding 0 0;
  }

  &-body .ant-table-tbody > tr:last-child > td {
    // remove last row border if the child is table
    border-bottom: 0;
  }

  &--fit-parent {
    flex: 1;
    overflow: hidden;

    .ant-card-body {
      height: 100%;
    }
  }

  &--table {
    .ant-card-body {
      padding: 0;
    }
  }
}

.self-service-layout {
  .ant-card {
    border-radius: 24px;
  }
}

// .ant-card {
//   .ant-list-sm .ant-list-item {
//     padding-left: 0;
//     padding-right: 0;
//   }
// }

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;