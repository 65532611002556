@import '../../../../styles/theme.less';

.tenant-switcher-page {
  background-color: @primary-bg-color;
  height: 100%;

  &__container {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    background-color: @white-color;
    padding: 24px 36px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .branding__logo {
      height: 30px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1600px;
    overflow-y: auto;
    padding: 16px 24px;
  }

  &__action-bar {
    display: flex;

    &--right {
      flex: 1;
      text-align: right;
    }
  }

  &__portal {
    margin-bottom: 16px;
    font-weight: bold;
    color: @greyish-color;
  }

  &__selection + &__portal {
    margin-top: 24px;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;