@import '../../../../../styles/theme.less';

.ir8a {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  padding: 0 24px;

  &__body {
    padding: 0 12px 12px;
  }

  .ir8a-form {
    padding-bottom: 25px;
    min-width: 1150px;
    max-width: 1500px;

    .amount-tooltip {
      position: absolute;
      top: 2px;
      right: -25px;
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      margin-top: 18px;
      padding: 5px 18px;
      background-color: @primary-bg-color;
    }

    &__header {
      font-weight: bold;
    }

    &__bordered {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0;
      padding-left: 15px;

      &-row {
        display: flex;
      }
    }

    &__nowrap {
      white-space: nowrap;
      padding-right: 10px;
    }

    &__field {
      display: flex;
      padding: 6px;
      padding-left: 0;

      &-name {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0 18px;

        .ant-typography-secondary {
          font-size: 12px;
          white-space: pre-line;
        }
      }

      &-row {
        display: flex;
        padding: 4px;
        padding-left: 0;
      }

      &-rowb {
        display: flex;
        padding-left: 0;
        text-align: left;

        &--bordered {
          display: flex;
          flex: 1;
        }
      }

      &-innertable {
        display: flex;
        margin-left: 30px;
        width: 82%;
      }

      &-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 15px;
        white-space: nowrap;
        justify-content: center;

        &--ac {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          justify-content: center;
          margin-left: 15px;
          margin-right: 15px;
        }

        &--ml {
          margin-left: 44px;
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
        }

        &--dp {
          width: 125px;
        }

        &--nm {
          margin: 0;
        }
      }

      &-contenttotal {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 25px;
        white-space: nowrap;
        font-weight: bold;
        text-align: right;
      }

      &-action {
        width: auto;
        justify-content: center;
        position: relative;
        // padding-right: 10px;

        .ant-select {
          width: 200px;
        }

        .ant-input-number-input {
          width: 120px;
        }
      }

      &-actionro {
        width: auto;
        justify-content: center;
        padding-right: 25px;
      }

      &-selectyn {
        width: 70px;
      }
    }

    &__input {

      &-select {
        width: 210px;
      }

      &-selectlg {
        width: 350px;
      }
    }
  }

  .ir8a-submit {
    width: 150px;
    height: 50px;
    position: relative;
    float: right;
    margin-left: 65%;
    z-index: 999;
    justify-content: center;
    align-items: center;
  }
  .ant-input-number {
    margin-left: 6px;
  }
  .ant-input-number-input {
    text-align: right;
    padding-right: 25px;
  }

  // text align
  .ta-r{
    text-align: right;
  }
  .ta-c{
    text-align: center;
  }

  // width
  .w15{
    width: 150px;
  }
  .w17 {
    width: 171.5px;
  }
  .w25{
    width: 250px;
  }
  .w30 {
    width: 300px;
  }
  .w35 {
    width: 360px;
  }
  .w44 {
    width: 440px;
  }
  .w54 {
    width: 540px;
  }
  .w67 {
    width: 677px;
  }
  .w400 {
    width: 422px;
  }
  .w432 {
    width: 432px;
  }
  .w442 {
    width: 442px;
  }
  .w452 {
    width: 452px;
  }
  .w461 {
    width: 461px;
  }

  // margin right
  .mr5 {
    margin-right: 5px;
  }
  .mr50 {
    margin-right: 50px;
  }
  .mr80 {
    margin-right: 80px;
  }
  .mr4vw {
    margin-right: 4vw;
  }

  // margin left
  .ml-5 {
    margin-left: -5px;
  }
  .ml-15 {
    margin-left: -15px;
  }
  .ml-25 {
    margin-left: -25px;
  }
  .ml0 {
    margin-left: 0;
  }
  .ml3 {
    margin-left: 3px;
  }
  .ml4 {
    margin-left: 4px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .ml15{
    margin-left: 15px;
  }
  .ml8{
    margin-left: 8px;
  }
  .ml200{
    margin-left: 200px;
  }
  .ml345{
    margin-left: 345px;
  }
  .ml36p {
    margin-left: 36.5%;
  }

  // margin top
  .mt-10 {
    margin-top: -10px;
  }
  .mt5 {
    margin-top: 5px;
  }
  .mt10 {
    margin-top: 10px;
  }

  // margin bottom
  .mb5 {
    margin-bottom: 5px;
  }

  // padding all
  .p5 {
    padding: 5px;
  }
  .pr55 {
    padding-right: 55px;
  }

  // padding left
  .pl3 {
    padding-left: 3px;
  }
  .pl40 {
    padding-left: 40px;
  }
  .pl08vw {
    padding-left: 0.8vw;
  }
  .pl2vw {
    padding-left: 2.5vw;
  }
  .pl3vw {
    padding-left: 3vw;
  }

  // padding top
  .pt4 {
    padding-top: 4px;
  }
  .pt5 {
    padding-top: 5px;
  }
  .pt15 {
    padding-top: 15px;
  }
  .pt20 {
    padding-top: 20px;
  }
  .pt25 {
    padding-top: 25px;
  }

  // padding right
  .pr10 {
    padding-right: 10px;
  }
  .pr28 {
    padding-right: 28px;
  }
  .pr60 {
    padding-right: 60px;
  }

  // max width
  .maxW140 {
    max-width: 140px;
  }
  .maxW150 {
    max-width: 150px;
  }
  .maxW170 {
    max-width: 170px;
  }
  .maxW159 {
    max-width: 159px;
  }
  .maxW200 {
    max-width: 200px;
  }
  .maxW224 {
    max-width: 224px;
  }
  .maxW232 {
    max-width: 232px;
  }
  .maxW240 {
    max-width: 240px;
  }
  .maxW250 {
    max-width: 240px;
  }
  .maxW255 {
    max-width: 265px;
  }
  .maxW257 {
    max-width: 257px;
  }
  .maxW300{
    max-width: 300px;
  }
  .maxW341{
    max-width: 341px;
  }
  .maxW349 {
    max-width: 349px;
  }
  .maxW440 {
    max-width: 440px;
  }
  .maxW457 {
    max-width: 457px;
  }
  .maxW522 {
    max-width: 522px;
  }
  .maxW540 {
    max-width: 540px;
  }
  .maxW557 {
    max-width: 557px;
  }
  .maxW17r {
    max-width: 16.8rem;
  }
  .maxW20vw {
    max-width: 20.4vw;
  }

  // min width
  .minW130 {
    min-width: 128px;
  }
  .minW150 {
    min-width: 150px;
  }
  .minW210 {
    min-width: 210px;
  }
  .minW220 {
    min-width: 220px;
  }
  .minW224 {
    min-width: 224px;
  }
  .minW232 {
    min-width: 232px;
  }
  .minW246 {
    min-width: 246px;
  }
  .minW255 {
    min-width: 265px;
  }
  .minW300 {
    min-width: 300px;
  }
  .minW342 {
    min-width: 342px;
  }
  .minW348 {
    min-width: 348px;
  }
  .minW349 {
    min-width: 349px;
  }
  .minW370 {
    min-width: 370px;
  }
  .minW407 {
    min-width: 407px;
  }
  .minW420 {
    min-width: 400px;
    max-width: 400px;
  }
  .minW630 {
    min-width: 630px;
  }
  .minW747 {
    min-width: 747px;
  }

  // min height
  .minH31 {
    min-height: 31.6px;
  }
  .minH62 {
    min-height: 63.2px;
  }

  // line height
  .lh2 {
    line-height: 2;
  }

  .divHeader {
    font-weight: bold;
    font-size: 16px;
    margin-left: 0;
  }
  .selectHeader {
    width: 400px;
  }

  .bdr-l {
    border-left: solid 1px #a9a9a9;
  }
  .bdr-t {
    border-top: solid 1px #a9a9a9;
  }
  .bdr-r {
    border-right: solid 1px #a9a9a9;
  }
  .bdr-b {
    border-bottom: solid 1px #a9a9a9;
  }
  .inputReadOnly {
    border: none;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;