@import '../../../../../styles/theme.less';

.a8a {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  padding: 0 24px;

  &__body {
    padding: 0 12px 12px;
  }

  .a8a-form {
    padding-bottom: 25px;
    width: 1200px;

    &__field-action {
      position: relative;

      .info-tooltip {
        position: absolute;
        top: 2px;
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      padding: 5px 18px;
      background-color: @primary-bg-color;
      margin-top: 10px;
    }

    &__header {
      font-weight: bold;
      margin-left: 5px;
    }

    &__bordered {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0;
      border: 1px solid black;
      padding-left: 15px;

      &-row {
        display: flex;
      }
    }

    &__nowrap {
      white-space: nowrap;
      padding-right: 10px;
    }

    &__field {
      display: flex;
      padding-top: 5px;
      padding-left: 0;

      &-name {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 18px;

        .ant-typography-secondary {
          font-size: 12px;
          white-space: pre-line;
        }
      }

      &-row {
        display: flex;
      }

      &-rowb {
        display: flex;
        padding-left: 0;
        text-align: left;

        &--bordered {
          display: flex;
          flex: 1;
          border: 1px solid black;
        }
        &--borderedb {
          display: flex;
          flex: 1;
          border-bottom: 1px solid black;
        }
        &--borderedbl {
          display: flex;
          flex: 1;
          border-bottom: 1px solid black;
          border-left: 1px solid black;
        }
        &--borderedbr {
          display: flex;
          flex: 1;
          border-bottom: 1px solid black;
          border-right: 1px solid black;
        }
      }

      &-innertable {
        display: flex;
        margin-left: 30px;
        width: 85%;
      }

      &-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 15px;
        white-space: nowrap;
        justify-content: center;

        &--ac {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          justify-content: center;
          margin-left: 15px;
          margin-right: 15px;
        }

        &--ml {
          margin-left: 44px;
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
        }

        &--dp {
          width: 125px;
        }

        &--nm {
          margin: 0;
        }
      }

      &-contenttotal {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 25px;
        white-space: nowrap;
        font-weight: bold;
        text-align: right;
      }

      &-action {
        width: auto;
        justify-content: center;
        position: relative;

        .ant-select {
          width: 200px;
        }

        .ant-input-number-input {
          width: 120px;
          text-align: right;
          padding-right: 28px;
        }
      }

      &-actionro {
        width: auto;
        justify-content: center;
        padding-right: 10px;
        margin-right: 30px;
      }

      &-selectyn {
        width: 70px;
      }
    }

    &__input {

      &-select {
        width: 210px;
      }

      &-selectlg {
        width: 350px;
      }
    }
  }

  // margin right
  .mr10 {
    margin-right: 10px;
  }
  .mr80 {
    margin-right: 80px;
  }

  // margin top
  .mt-5 {
    margin-top: -5px;
  }
  .mt-10 {
    margin-top: -10px;
  }
  .mt0 {
    margin-top: 0;
  }
  .mt10 {
    margin-top: 10px;
  }

  // margin left
  .ml-15 {
    margin-left: -15px;
  }
  .ml-25 {
    margin-left: -25px;
  }
  .ml15 {
    margin-left: 15px;
  }
  .ml20 {
    margin-left: 20px;
  }
  .ml23 {
    margin-left: 23px;
  }
  .ml25 {
    margin-left: 25px;
  }
  .ml30 {
    margin-left: 30px;
  }
  .ml40 {
    margin-left: 40px;
  }

  // width
  .w17 {
    width: 171.5px;
  }
  .w30 {
    width: 300px;
  }
  .w35 {
    width: 360px;
  }
  .w54 {
    width: 540px;
  }
  .w67 {
    width: 677px;
  }
  .w150 {
    width: 150px;
  }
  .w370 {
    width: 370px;
  }
  .w520 {
    width: 520px;
  }

  .h30 {
    height: 30px;
  }

  .pb8 {
    padding-bottom: 8px;
  }
  .boldText {
    font-weight: bold;
  }
  .Tooltip2b {
    align-items: start;
    text-align: left;
  }
  .selectHeader {
    width: 400px;
  }

  // min width
  .minW150 {
    min-width: 150px;
  }

  // max width
  .maxW150 {
    max-width: 150px;
  }
  .maxW180 {
    max-width: 180px;
  }
  .maxW200 {
    max-width: 200px;
  }

  .pt0 {
    padding-top: 0;
  }

  .autoFill {
    font-weight: bold;
    border: none;
    text-align: right;
    padding-right: 30px;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;