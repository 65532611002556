@import '../../../../styles/theme.less';

.ro-schedule-records {
  background-color: @white-color;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding: 0 16px 16px;
    max-width: 1600px;

    .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td:last-child {
      padding-right: @table-padding-horizontal-md;
    }
  }

  .view-criteria-simple {
    flex: 1;
  }

  &__action-bar {
    display: flex;
    gap: 10px;

    .ant-select-multiple {
      .ant-select-selector {
        .ant-select-selection-item {
          color: @white-color;
          background-color: @primary-color;
          border: 0;
          font-weight: bold;
          border-radius: @border-radius-base - 2;
        }

        .ant-select-selection-item-remove {
          color: @white-color;
        }
      }
    }
  }

  .search-input {
    /* temporary */
    margin-right: 0;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 6px 4px;
  }

  .shift-day {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
    }

    &__add {
      border-color: @grey-color;
      width: 100%;
      min-height: 36px;
      border-radius: @border-radius-base;

      i {
        color: @grey-color;
      }

      &:hover {
        border-color: @primary-color;

        i {
          color: @primary-color;
        }
      }
    }

    &__dow {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.3;
    }

    &__summary {
      font-size: 12px;
      color: @greyish-color;
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;