@import '../../styles/theme.less';

.table__wrapper {
  position: relative;

  .table__loading {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .table__loading-icon {
    font-size: 18px;
  }
}

.ant-table-wrapper {
  .ant-table {
    .ant-table-thead > tr > th {
      font-weight: 700;
      font-size: 12px;
    }

    &.ant-table-middle,
    &.ant-table-small {
      .ant-table-tbody > tr > td {
        &[rowspan] {
          vertical-align: top;
        }
      }

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        &:first-child {
          padding-left: @table-padding-horizontal-md + 12;

          &.ant-table-selection-column {
            padding-left: @table-padding-horizontal-md;
          }
        }
        &:last-child {
          padding-right: @table-padding-horizontal-md + 12;
        }
      }

      // Decrease indent of child table
      .ant-table-tbody {
        .ant-table-wrapper:only-child {
          .ant-table {
            margin-left: 0;
          }
        }
      }

      &.ant-table-has-fix-left {
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          &:first-child {
            padding-left: @table-padding-horizontal-md;

            &.ant-table-cell-fix-left.first-col {
              padding-left: @table-padding-horizontal-md + 12;
            }
          }
        }
      }
    }

    // custom expand icon
    .ant-table-row-expand-btn {
      display: inline-block;
      margin-right: 0;
      color: #bbb;
      outline: none;
      transition: all 0.3s;
      user-select: none;
      cursor: pointer;
      width: 12px;
      height: 12px;
      line-height: 0;

      svg {
        width: 12px;
        height: 12px;
      }

      + div {
        display: inline-flex;
      }

      &-collapsed {
        transform: rotate(-180deg);
      }

      &-spaced {
        background: transparent;
        border: 0;
        visibility: hidden;
      }
    }

    .ant-table-selection-col {
      width: 30px;
    }
  }

  .ant-table-pagination {
    flex: none;
    margin: 8px 0;
  }
}

.ant-table-expanded-row.ant-table-expanded-row-level-1 {
  margin-top: -1px;

  .ant-table-container {
    &::after, &::before {
      box-shadow: none !important;
    }
  }

  .ant-table-cell {
    background: white;
  }
}

.table__wrapper--fit-parent {
  height: 100%;

  .ant-table-wrapper {
    height: 100%;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;

        .ant-table {
          flex: auto;
          overflow: hidden;

          .ant-table-container {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;

            .ant-table-header {
              flex: none;
            }

            .ant-table-body {
              flex: auto;
              max-height: none;
            }
          }
        }
      }
    }
  }
}

/* grip handle for dragging */
.ant-table {
  td.ant-table-cell {
    i.fa-grip-dots-vertical {
      width: 20px;
    }

    &.fit-width {
      width: 1%;
      white-space: nowrap;
    }
  }
}

.self-service-layout {
  .ant-table {
    border-radius: 24px;

    .ant-table-container {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;

      table {
        border-radius: 24px 24px 0 0;

        > thead {
          > tr:first-child th:first-child {
            border-top-left-radius: 24px;
          }
          > tr:first-child th:last-child
          {
            border-top-right-radius: 24px;
          }
        }
      }
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;