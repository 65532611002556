@import '../../../../../styles/theme.less';

.permission-action {
  &:first-child {
    .permission-action__title {
      margin-top: 0;
    }
  }

  &:last-child {
    margin-bottom: 24px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 18px;
    padding: 7px 18px;
    background-color: @primary-bg-color;
  }

  &__service {
    display: flex;
    padding: 12px;
    padding-left: 0;

    &:nth-child(odd) {
      background-color: #f9f9f9;
    }

    &--level-1 {
      padding: 2px 12px 2px 24px;
      align-items: center;
    }

    &-name {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0 18px;

      .ant-typography-secondary {
        font-size: 12px;
        white-space: pre-line;
      }
    }

    &-action {
      width: 200px;

      .ant-select {
        width: 200px;
      }
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;