@import '../../../../../styles/theme.less';

.a8b {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  height: 100%;
  padding: 0 24px;

  &__body {
    padding: 0 12px 12px;
  }

  .a8b-form {
    padding-bottom: 25px;
    min-width: 1355px;

    &__title {
      font-size: 14px;
      font-weight: 600;
      padding: 5px 18px;
      background-color: @primary-bg-color;
    }

    &__header {
      font-weight: bold;
      margin-left: 5px;
    }

    &__bordered {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0;
      border: 1px solid black;
      padding-left: 15px;

      &-row {
        display: flex;
      }
    }

    &__nowrap {
      white-space: nowrap;
      padding-right: 10px;
    }

    &__field {
      display: flex;
      padding: 12px;
      padding-left: 0;

      &-name {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0 18px;

        .ant-typography-secondary {
          font-size: 12px;
          white-space: pre-line;
        }
      }

      &-row {
        display: flex;
        padding: 7px;
        padding-left: 0;
      }

      &-rowb {
        display: flex;
        padding-left: 0;
        text-align: left;

        &--bordered {
          display: flex;
          flex: 1;
          border: 1px solid black;
        }
        &--borderedb {
          display: flex;
          flex: 1;
          border-bottom: 1px solid black;
        }
        &--borderedbl {
          display: flex;
          flex: 1;
          border-bottom: 1px solid black;
          border-left: 1px solid black;
        }
        &--borderedbr {
          display: flex;
          flex: 1;
          border-bottom: 1px solid black;
          border-right: 1px solid black;
        }
      }

      &-innertable {
        display: flex;
        margin-left: 30px;
        width: 85%;
      }

      &-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 15px;
        white-space: nowrap;
        justify-content: center;

        &--ac {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          justify-content: center;
          margin-left: 15px;
          margin-right: 15px;
        }

        &--ml {
          margin-left: 44px;
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
        }

        &--dp {
          width: 125px;
        }

        &--nm {
          margin: 0;
        }
      }

      &-contenttotal {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 25px;
        white-space: nowrap;
        font-weight: bold;
        text-align: right;
      }

      &-selectyn {
        width: 70px;
      }
    }

    &__input {

      &-select {
        width: 210px;
      }

      &-selectlg {
        width: 350px;
      }
    }
  }

  .a8b-submit {
    width: 150px;
    height: 50px;
    position: relative;
    float: right;
    margin-left: 65%;
    z-index: 999;
    justify-content: center;
    align-items: center;
  }

  // width
  .w17 {
    width: 171.5px;
  }
  .w30 {
    width: 300px;
  }
  .w35 {
    width: 360px;
  }
  .w54 {
    width: 540px;
  }
  .w67 {
    width: 677px;
  }
  .w370 {
    width: 370px;
  }
  .w520 {
    width: 520px;
  }

  // min width
  .minW150 {
    min-width: 150px;
  }

  // max width
  .maxW200 {
    max-width: 200px;
  }

  // margin right
  .mr80 {
    margin-right: 80px;
  }

  // margin top
  .mt10 {
    margin-top: 10px;
  }

  // margin left
  .ml-5 {
    margin-left: -5px;
  }
  .ml-15 {
    margin-left: -15px;
  }
  .ml15 {
    margin-left: 15px;
  }
  .ml20 {
    margin-left: 20px;
  }
  .ml30 {
    margin-left: 30px;
  }
  .ml40 {
    margin-left: 40px;
  }
  
  .boldText {
    font-weight: bold;
  }
  .Tooltip2b {
    margin-right: 700px;
  }
  .divHeader {
    font-weight: bold;
    font-size: 16px;
    margin-left: -25px;
  }
  .selectHeader {
    width: 400px;
  }
  
  .autoFill {
    border: none;

    input.ant-input-number-input {
      font-weight: bold;
    }
  }

  .tableBordered {
    border-collapse: collapse;
    border: 1px solid #a9a9a9;

    th, td {
      border-collapse: collapse;
      border: 1px solid #a9a9a9;

      .ant-input-number-input {
        width: 140px;
        text-align: right;
      }
    }

    .td-small {
      .ant-input-number-input {
        width: 90px;
        text-align: right;
      }
    }

    tfoot {
      font-weight: bold;
    }
  }

  .tableHeader {
    text-align: center;
  }

  .centered {
    text-align: center;
  }

  .yn {
    margin-left: 50px;
    margin-top: 20px;
  }

  .ant-input-number-input {
    width: 88px;
    text-align: right;
    padding-right: 25px;
  }

  .dateHeader {
    .ant-form-item-control-input-content {
      min-width: 125px;
    }
  }

  th {
    text-align: left !important;
    padding-top: 10px !important;
    padding-left: 5px !important;
  }

  .pr10 {
    padding-right: 10px !important;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;