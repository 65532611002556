.my-time-log-task-token {
  display: flex;
  justify-content: center;
  padding: 48px 0;
  width: 95%;
  margin: 0 auto;

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__body {
    > .ant-col + .ant-col {
      border-left: 1px solid @light-color;
    }
  }

  .ant-card {
    max-width: 800px;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ant-table-wrapper {
      margin-bottom: 24px;
    }
  }

  &--completed {
    .my-time-log-task-token__footer {
      margin-top: 20px;
      
      .ant-space {
        text-align: center;
      }
    }
  }

  .ssleave-record-comments {
    width: 350px;
  }

  .button-group {
    button {
      min-width: 80px;
    }
  }
}


@media only screen and (max-width: 768px) {
  .my-time-log-task-token {
    padding: 0;

    .ant-card {
      border: 0;
      box-shadow: none;
      width: 100% !important;
    }

    &__body {
      > .ant-col-12 {
        max-width: 100%;
        flex: 0 0 100%;
      }
      
      > .ant-col + .ant-col {
        border-left: 0;
      }
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;