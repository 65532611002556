@import '../../../../styles/theme.less';

.daily-record {
  background-color: @white-color;
  height: 100%;
  overflow: auto;

  &__header {
    padding-bottom: 15px;
    max-width: 1200px;

    &-left {
      align-self: flex-start;
    }

    &-date {
      display: flex;

      &-prev {
        border-radius: @border-radius-base 0 0 @border-radius-base;
      }
      &-next {
        border-radius: 0 @border-radius-base @border-radius-base 0;
      }
      .ant-btn {
        margin-left: -1px;

        > i {
          font-size: 10px;
        }
      }
    }

    &-date-select {
      width: 100%;
      margin-right: 8px;

      input {
        font-weight: 600;
      }
    }

    &-em-select {
      width: 320px;

      .ant-select-selector{
        min-height: 50px;
        vertical-align: middle;

        .ant-select-selection-item {
          padding-top: 5px;
        }
      }
    }

    &-summary {
      font-size: 14px;
      font-weight: normal;
      min-height: 92px;
      line-height: 1.4;

      label {
        color: @text-color-secondary;
        font-size: 13px;
      }

      &-value {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-box {
        border: 1px solid @border-color-base;
        padding: 12px;
        width: 140px;
        margin-left: -1px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:first-child {
          border-radius: @border-radius-base 0 0 @border-radius-base;
        }
        &:last-child {
          border-radius: 0 @border-radius-base @border-radius-base 0;
        }

        .shift-time {
          font-size: 12px;
        }
      }

      &-lock {
        padding-left: 16px;
        display: flex;
        align-items: flex-end;
      }
    }

    &-actions {
      min-width: 110px;

      > .ant-space-item, .ant-btn:not(.ant-btn-icon-only) {
        min-width: 110px;
      }

      &-recalculate {
        display: flex;

        .ant-btn {
          margin-left: -1px;
        }
        .ant-btn:first-child {
          border-radius: @border-radius-base 0 0 @border-radius-base;
        }
        .ant-btn:last-child {
          border-radius: 0 @border-radius-base @border-radius-base 0;
        }
      }
    }
  }

  &__body {
    padding: 0 24px 24px;
    max-width: 1100px;

    &-empty {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 24px;

      .ant-card {
        width: 100%;
      }
    }

    &-form {
      display: flex;
      column-gap: 24px;
      align-items: flex-start;
    }

    &-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 12px;

      .ant-card {
        width: 100%;
      }
    }

    &-right {
      width: 400px;
    }

    .section-title {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 4px;
    }
  }

  &__lock {
    .fal {
      width: 18px;
      height: 18px;
    }
  }

  .daily-record-labels {
    margin-top: 20px;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;