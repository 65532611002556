.make-space(@value: 8);
.make-space(@value: 0);
.make-space(@value: -12);

.make-space(@value) {
  .p@{value} { padding: ~"@{value}px"; }
  .pt@{value} { padding-top: ~"@{value}px"; }
  .pl@{value} { padding-left: ~"@{value}px"; }
  .pr@{value} { padding-right: ~"@{value}px"; }
  .pb@{value} { padding-bottom: ~"@{value}px"; }
  .py@{value} { padding-top: ~"@{value}px"; padding-bottom: ~"@{value}px"; }
  .px@{value} { padding-left: ~"@{value}px"; padding-right: ~"@{value}px"; }
  .m@{value} { margin: ~"@{value}px"; }
  .mt@{value} { margin-top: ~"@{value}px"; }
  .ml@{value} { margin-left: ~"@{value}px"; }
  .mr@{value} { margin-right: ~"@{value}px"; }
  .mb@{value} { margin-bottom: ~"@{value}px"; }
  .my@{value} { margin-top: ~"@{value}px"; margin-bottom: ~"@{value}px"; }
  .mx@{value} { margin-left: ~"@{value}px"; margin-right: ~"@{value}px"; }
}
