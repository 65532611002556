/*!
 * Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
@import "_variables.less";

:root, :host {
  --@{fa-css-prefix}-style-family-classic: '@{fa-style-family}';
  --@{fa-css-prefix}-font-light: normal 300 1em/1 '@{fa-style-family}';
}

.fal,
.@{fa-css-prefix}-light {
  font-weight: 300;
}
