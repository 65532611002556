@import '../../styles/theme.less';

.ant-badge {
  padding: 1px 5px 2px;
  border-radius: 4px;
  line-height: 1.2;

  &.badge {
    &--neutral {
      .ant-badge-count {
        background-color: #eaeaea;
        color: #616161;
        border-color: #d3dade;
      }
    }
    &--success {
      .ant-badge-count {
        background-color: #f6ffed;
        color: #389e0d;
        border-color: #b7eb8f;
      }
    }
    &--primary {
      .ant-badge-count {
        background-color: #e6f7ff;
        color: #096dd9;
        border-color: #91d5ff;
      }
    }
    &--secondary {
      .ant-badge-count {
        background-color: #fff7e6;
        color: @secondary-color;
        border-color: #ffd591;
      }

      &-filled {
        .ant-badge-count {
          color: @white-color;
          background-color: @secondary-color;
          border-color: @secondary-color;
        }
      }
    }
    &--tertiary {
      .ant-badge-count {
        background-color: #f9f0ff;
        color: #531dab;
        border-color: #d3adf7;
      }
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;