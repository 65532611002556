@import '../../../styles/theme.less';

.wf-events {
  height: 100%;
  max-width: 900px;

  &__header {
    padding: 0 16px 16px;
    display: flex;
    justify-content: flex-end;
  }

  &__body {
    padding: 0 16px 16px;
  }

  &__table-row__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .view-criteria-advanced {
    margin-bottom: 8px;
  }

  &__row-dragging {
    background: @table-row-hover-bg;
    opacity: 0.7;
    border: 1px solid @light-color;
    z-index: 1001;
    display: flex;
    align-items: center;
  
    td {
      padding: @table-padding-vertical-md @table-padding-horizontal-md;
      visibility: hidden;

      &:first-child {
        padding-left: @table-padding-horizontal-md + 12;
      }
      &:last-child {
        padding-right: @table-padding-horizontal-md + 12;
      }
    }
  
    .drag-visible {
      visibility: visible;
    }
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;