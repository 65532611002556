@import '../../../../styles/theme.less';

.payrecords {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;

  .ant-page-header-heading-left {
    align-items: flex-end;
  }
  .ant-page-header-heading-extra {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  &__view-save,
  &__view-delete {
    margin-left: 8px;
  }

  .view-criteria-simple {
    flex: 1;
  }

  &__action-bar {
    display: flex;

    &-buttons-lock {
      padding: 4px 8px;
    }
  }

  &__footer-bar {
    margin-top: 16px;
    display: flex;

    &--flex {
      flex: 1;
    }
  }

  .ant-table .ant-table-expanded-row > .ant-table-cell {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__trans-table {
    margin-left: 40px;
    margin-bottom: 10px;

    .ant-table-container {
      &::before, &::after {
        box-shadow: none !important;
      }
    }
  }

  &__trans-add {
    margin: 3px 0;
  }

  &__lock {
    height: 18px;
    display: block;

    svg {
      width: 18px;
      height: 18px;
      fill: @light-grey-color;
      stroke: @light-grey-color;
    }

    &--locked {
      svg {
        fill: @greyish-color;
      }
    }
  }

  .action-cell {
    padding: 0 !important;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;