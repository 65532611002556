@import '../../../../styles/theme.less';

.payrun-card {
  max-width: 480px;
  min-width: 400px;
  margin: 18px;

  &__header {
    border-bottom: 1px solid @light-color;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &-title {
      font-size: 24px;
      font-weight: 500;
      color: @greyish-color;
    }
  }

  &__summary {
    font-size: 12px;
    min-width: 220px;

    > .ant-row {
      font-weight: 500;
      > .ant-col:first-child {
        font-weight: normal;
        color: @greyish-color;
      }
      > .ant-col:last-child {
        text-align: right;
      }
    }
  }

  &__count {
    font-size: 12px;
    min-width: 100px;
    margin-left: 20px;

    > .ant-row {
      > .ant-col:first-child {
        color: @greyish-color;
      }
      > .ant-col:last-child {
        font-weight: 500;
        text-align: right;
      }
    }
  }

  &__dot {
    font-size: 10px;
    color: @darkish-color;
    margin-left: 7px;
    margin-right: 7px;
  }

  &__overlay {
    height: 100px;

    &-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    text-overflow: ellipsis;
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;