@import '../../../styles/theme.less';

.view-criteria-advanced {
  margin-bottom: @form-item-margin-bottom;

  &__row-item {
    margin-bottom: 8px;

    &--indented {
      margin-left: 30px !important;
    }
  }

  &__add {
    margin-left: -7px;
  }

  > label {
    display: block;
    font-size: 13px;
    color: @greyish-color;
    padding: 0 0 3px;
    height: 25px;
  }

  .criteria-group__label, .criteria-item__label {
    font-size: 13px;
    color: @greyish-color;
    padding-bottom: 3px;
  }

  .criteria-item{
    &__label {
      margin-top: 15px;
    }

    &__logical-operator {
      cursor: pointer;
      text-transform: uppercase;
      padding: 3px 5px;
      background-color: white;

      .fa-chevron-down {
        color: @grey-color;
      }
    }
  }

  .criteria-empty {
    font-size: 14px;
    margin: 6px 0;
  }

  .ant-select {
    width: 100%;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;