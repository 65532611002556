::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 14px;
  border: 3px solid @light-color;
}

::-webkit-scrollbar-track-piece {
  background: @light-color;
}
