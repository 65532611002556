// bordered + pulled icons
// -------------------------

.@{fa-css-prefix}-border {
  border-color: ~'var(--@{fa-css-prefix}-border-color, @{fa-border-color})';
  border-radius: ~'var(--@{fa-css-prefix}-border-radius, @{fa-border-radius})';
  border-style: ~'var(--@{fa-css-prefix}-border-style, @{fa-border-style})';
  border-width: ~'var(--@{fa-css-prefix}-border-width, @{fa-border-width})';
  padding: ~'var(--@{fa-css-prefix}-border-padding, @{fa-border-padding})';
}

.@{fa-css-prefix}-pull-left { 
  float: left;
  margin-right: ~'var(--@{fa-css-prefix}-pull-margin, @{fa-pull-margin})'; 
}

.@{fa-css-prefix}-pull-right { 
  float: right;
  margin-left: ~'var(--@{fa-css-prefix}-pull-margin, @{fa-pull-margin})'; 
}
