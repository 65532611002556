.primary-color {
  color: @primary-color;
}
.secondary-color {
  color: @secondary-color;
}
.darkish-color {
  color: @darkish-color;
}
.greyish-color {
  color: @greyish-color;
}
.grey-color {
  color: @grey-color;
}
.light-grey-color {
  color: @light-grey-color;
}
.light-color {
  color: @light-color;
}
